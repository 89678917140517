<template>
	<div>
		<Hero image="../img/8.jpg" />
		<main>
			<div class="container">
				<Heading title="Belief Statement" />
				<div class="content">
					<div class="row">
						<div class="col-md-8">
							<ul>
                                <li>Education is a shared responsibility of the school, pupil, family, community, and government. </li>
                                <li>Education works best when there is mutual respect among teachers, parents, pupils, and the community. </li>
                                <li>The school environment meets the emotional, academic, social, and physical needs of each pupil along with stimulating desire to explore and participate in activities in and beyond the classroom. </li>
                                <li>Preparation for career decisions and higher education is essential to the future success of every pupil. </li>
                                <li>A collaborative learning environment fosters a sense of belonging, cultivating creative thinking, and problem-solving. </li>
                                <li>The school sets high expectations and provides meaningful and challenging instructions, allowing pupils to achieve their highest potential. </li>
                                <li>The school community respect, protect and celebrates the diversity, talent, and potential to learn, by all pupils.</li>
                            </ul>
						</div>
						<div class="col-md-4">
							<MenuAbout />
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	components: {},
};
</script>
